* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --primary-color: #0000A9;
  --primary-color-pressed: #0000cc;
  --sub-text: #969696;
  --green-color: #76B629;
}

.subtextColor {
  color: var(--sub-text);
}

.greenText {
  color: var(--green-color);
}

.smallText {
  font-size: 12px;
}

.marginTop20 {
  margin-top: 20px;
}

.bold{
  font-weight: bold;
}

.hide{
  display: none;
}

.flex{
  display: flex;
}

button {
  border-radius: .4rem;
  padding: 1rem 2rem;
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

button:active{
  background-color: var(--primary-color-pressed);
}

a {
  all: initial;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #1E1E1E;
  cursor: pointer;
}

h1 {
  font-size: 60px;
  text-align: center;
  max-width: 850px;
  line-height: normal;
}

h2 {
  font-size: 40px;
  text-align: center;
  max-width: 850px;
  line-height: normal;
}

h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: normal;
}

h4 {
  font-size: 25px;
}

p {
  font-size: 20px;
  max-width: 850px;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #1E1E1E;
  margin: 0;
  padding: 0;
}

.Site{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

header {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  height: 86px;
  padding: 10px;
}

header .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

header .logo {
  width: 130px;
}

header .center {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem
}

header .menuItens {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem
}

header .center .menuItens a {
  text-decoration: none;
  font-weight: bold;
  padding: 1rem;
}

header .center .menuItens a:hover {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.menuItens button {
  width: 90%;
}

header .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

header .menuButton {
  display: none;
  cursor: pointer;
  padding: 10px;
}

.menu {
  position: absolute;
  top: 86px;
  width: 100%;
  background-color: white;
  display: none;
}

.menu .menuItens {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.menu .menuItens a{ 
  width: 100%;
  text-align: center;
  padding: .5rem;
  font-weight: bold;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
}

section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

section#top{
  background-image: url('./assets/images/BackgroundHome01.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  margin-top: 40px;
}

section .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  margin: 20px 0;
  padding: 0 20px;
  gap: 40px;
  text-align: center;
}

section#top img {
  width: 70%;
  margin: 40px 0;
}

section#recursos {
  margin: 20px 0;
}

.indicadores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  max-width: 1000px;
  gap: 20px;
  margin: 20px 0;
}

.indicadores .card {
  width: 33%;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.indicadores .card:hover {
  transform: scale(1.05);
}

.indicadores .cardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: red;
  height: 5rem;
  padding: 1rem;
  text-align: center;
}

.indicadores .cardContent {
  font-size: 16px;
  padding: 20px;
  text-align: left;
}

.indicadores .cardContent ul {
  margin: 10px 20px;
}

section#blackBar .blackBar {
  width: 80%;
}

section .blackBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1rem;
  background-color: #1E1E1E;
  color: white;
  padding: 4rem;
  width: 100%;
}

section .blackBar h3 {
  text-align: left;
  width: 50%;
}

section.dashboardSection{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
}

section .dashboardContent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 35%;
  padding: 20px;
  z-index: 1;
}

section .dashboardContent h2{
  text-align: left;
  margin-bottom: 10px;
}

section.dashboardSection img{
  /* height: 100%; */
  /* max-height: 700px; */
  width: 60%;
  justify-self: flex-end;
  align-items: flex-end;
  /* width: fit-content; */
}

.ofensores {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 40px 0;
  background-image: linear-gradient(180deg, #ED6114 0%, #ED0F31 100%);
  color: white;
  text-align: center;
}

.ofensores img {
  width: 80%;
}

.ofensoresTop {
  transform: translateY(7px) rotate(180deg);
}

.cenariosTop {
  transform: translateY(41px) rotate(180deg);
}

section.predicao {
  gap: 20px;
}

section .predicao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #1E1E1E;
  color: white;
  padding: 40px;
  width: 70%;
  gap: 40px;
  height: fit-content;
  margin: 40px 0;
}

section .predicao img {
  width: 40%;
}

section .predicao .content {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;
}

section .predicao .content h2 {
  text-align: start;
}

.cenariosPlanos {
  width: 100%;
  color: white;
  background-image: linear-gradient(180deg, #252F5F 0%, #AE050B 100%);
  padding-bottom: 100px;
}

section.cenarios { 
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;
}

section.cenarios img { 
  width: 90%;
  max-width: 950px;
}

section#planos {
  padding-top: 40px;
}

section#planos h2{
  margin-bottom: 60px;
}

section .planos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80%;
  padding-bottom: 40px;
}

section .planos .plano {
  background-color: #1E1E1E;
  border-radius: 20px;
  color: white;
  padding: 40px 30px 60px 30px;
  width: 33%;
  text-align: left;
  transition: transform 0.3s ease-in-out;
}

section .planos .plano:hover {
  transform: scale(.95);
}

.plano h1, .plano h2, .plano h3 {
  text-align: left;
}

.plano .items {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plano .item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.plano .itemText {
  width: 90%;
}

section#quemSomosNos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 60px;
}

section .integrantes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 90%;
}

section .integrante{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 20px;
  color: white;
  background-color: #1E1E1E;
  padding: 80px;
  transition: transform 0.3s ease-in-out;
}

section .integrante:hover{
  transform: scale(1.05);
}

section .integrante h3{
  font-weight: bold;
}

section .integrante p{
  text-align: center;
}

section .integrante img{
  width: 195px;
}

.separador{
  flex: 1
}

.toTopButton{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 36px;
  height: 36px;
  background-color: var(--primary-color-pressed);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}

.toTopButton:active{
  background-color: var(--primary-color-pressed);
}

section#faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
  width: 90%;
  max-width: 1440px;
  padding-top: 40px;
}

.faqs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown p {
  text-align: left;
  max-width: 1440px;
  width: 100%;
}

.dropdown .button{
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border: 1px solid black;
  padding: .8rem;
}

.dropdown .content{
  width: 100%;
  border: 1px solid black;
  padding: .8rem;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.dropdown .content p{
  color: #5f5f5f;
  font-size: 16px;
}

footer {
  width: 100%;
  background-color: #121212;
  padding: 40px 60px 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
}

footer .footerContent{
  width: 100%;
  max-width: 1440px;
}

section#footerLogo{
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 20px;
}

footer section.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 60px;
}

footer section.content .footerMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

footer section.content .footerMenu p {
  transition: transform .3s ease-in-out;
  cursor: pointer;
}

footer section.content .footerMenu p:hover {
  transform: scale(1.05);
}

footer .contato{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

footer .contato .icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  header .logo {
    width: 75px;
  }

  header .menuItens {
      display: none;
  }

  header .loginButton {
    display: none;
  }

  header .menuButton {
    display: flex;
  }

  section#top img {
    width: 90%;
  }

  .topBottom{
    display: none;
  }

  .indicadores {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .indicadores .card{
    width: 100%;
  }

  section .blackBar {
    flex-direction: column;
    margin: 0;
    width: 90%;
    padding: 40px;
    gap: 10px;
  }

  section .blackBar h3 {
    width: 100%;
    text-align: center;
  }

  section.dashboardSection{
    flex-direction: column;
    gap: 40px;
  }
  section .dashboardContent{
    margin: 20px;
    padding: 0;
    width: 90%;
  }
  section .dashboardContent h2{
    text-align: center;
  }

  section.dashboardSection img{
    width: 100%;
  }

  section .predicao {
    flex-direction: column;
    width: 90%;
  }

  section .predicao .content {
    width: 100%;
  }

  section .predicao img {
    width: 100%;
  }

  section .predicao .content h2 {
    text-align: center;
  }

  section .planos {
    display: flex;
    flex-direction: column;
  }

  section .planos .plano {
    width: 100%;
  }

  section#planos h2{
    margin-bottom: 30px;
  }

  section .integrantes{
    flex-direction: column;
  }

  footer {
    padding: 20px 0px;
  }

  section#footerLogo{
    display: flex;
    align-items: center;
  }

  footer section.content {
    align-items: center;
    justify-content: center;
  }

  footer section.content .footerMenu {
    display: none;
  }

  footer section.content .separador {
    display: none;
  }

  .menu{
    display: flex;
  }
}